<template>
  <div class="aiRecordList overflow-y-auto">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <div
        class="recordBox"
        v-for="item in state.list"
        :key="item.id"
        @click="
          openBigImg(item.localStatus == '2' ? item.newPic : [item.originPic])
        "
      >
        <div class="createdAt">
          <span>创建时间 {{ item.createdAt | orderDate }}</span
          ><SvgIcon
            v-if="item.localStatus == 1"
            iconClass="processing"
            class="processing"
          ></SvgIcon>
          <SvgIcon
            v-else-if="item.localStatus == 2"
            iconClass="processing"
            class="aiSuccess"
          ></SvgIcon>
          <span
            class="delBtn"
            v-else-if="item.localStatus == 3"
            @click="openDelChangeVideoFaceDialog(item.id)"
            ><SvgIcon iconClass="aiClose" class="aiClose"></SvgIcon>删除</span
          >
        </div>
        <div class="imageBox">
          <div class="originPicBox">
            <div class="lable">素材</div>
            <ImgDecypt class="cover" :src="item.originPic"></ImgDecypt>
          </div>
          <SvgIcon iconClass="equalSign" class="equalSign"></SvgIcon>
          <div class="resultImg">
            <div class="mask" v-if="item.localStatus !== '2'">
              <SvgIcon
                iconClass="aiFail"
                class="aiFail"
                v-if="item.localStatus == 3"
              ></SvgIcon>
            </div>
            <ImgDecypt
              class="cover"
              :src="item.localStatus == '2' ? item.newPic[0] : item.originPic"
            ></ImgDecypt>
          </div>
        </div>
        <div class="statusBox">
          <div class="statusText" v-if="item.localStatus == '1'">
            后台正在进行处理中...
          </div>
          <div class="statusText" v-else-if="item.localStatus == '2'">
            生成耗时：{{ item.spendTime }}
          </div>
          <div class="statusText errorText" v-else-if="item.localStatus == '3'">
            {{ item.remark }}
          </div>
          <div class="queueBtn" v-if="item.localStatus == '1'">排队中</div>
          <div class="successBtn" v-else-if="item.localStatus == '2'">
            已成功
          </div>
          <div class="failBtn" v-else-if="item.localStatus == '3'">已失败</div>
        </div>
      </div>
    </PullRefresh>
  </div>
</template>
<script>
import PullRefresh from "@/components/PullRefresh";
// import Loading from "@/components/Loading";
import { generateImg, getAiMod, getAiUndressRecord } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import SvgIcon from "@/components/SvgIcon";
import { dateFormat1 } from "@/utils/index.js";

export default {
  name: "aiUndressList",
  inject: ["openBigImg", "openDelUndressDialog", "openReasonDialog"],
  props: {
    status: String,
  },
  components: {
    ImgDecypt,
    PullRefresh,
    SvgIcon,
  },
  computed: {},
  data() {
    return {
      state: {
        pageNumber: 1,
        pageSize: 10,
        list: [],
        status: this.status,
        delId: "", // 当前删除ID
      },
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  watch: {
    status: {
      immediate: true,
      handler(newStatus) {
        this.localStatus = newStatus;
      },
    },
  },
  created() {},
  mounted() {
    this.getList("refresh");
  },
  methods: {
    /**
     * 获取列表
     */
    async getList(type) {
      let req = {
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        status: this.state.status,
      };
      try {
        let res = await this.$Api(getAiUndressRecord, req);
        console.log(res, "-==-=-");
        const { data } = res;
        this.loading = false;
        this.refreshing = false;
        let list = data.list || [];
        for (const item of list) {
          item.localStatus = this.localStatus;
          item.createdAt = dateFormat1(item.createdAt);
        }
        console.log(list);
        if (type === "refresh") {
          this.state.list = list;
        } else {
          this.state.list = this.state.list.concat(list);
        }
        if (this.state.pageNumber == 1 && !this.state.list.length) {
          this.isNoData = true;
        }
        if (list.length < this.state.pageSize) {
          this.finished = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    onRefresh() {
      this.state.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.state.pageNumber++;
      }
      this.getList();
    },
    delId(id) {
      let newArr = [];
      newArr = this.state.list.filter((item) => {
        if (item.id != id) return item;
      });
      this.state.list = newArr;
      if (this.state.list.length == 0) {
        this.finished = false;
        this.isNoData = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.aiRecordList {
  //height: calc(100vh - 135px);
  padding: 0 16px;

  .recordBox {
    //height: 92px;
    //width: 100%;
    margin: 0 auto 10px;
    background: #ffffff;
    border-radius: 8px;
    padding: 12px;
    justify-content: flex-start;
    .createdAt {
      font-size: 12px;
      color: #999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .processing {
        width: 18px;
        height: 18px;
      }
      .delBtn {
        display: flex;
        align-items: center;
        color: #fbd13d;
      }
      .aiClose {
        width: 18px;
        height: 18px;
        margin-left: 2px;
      }
    }
    .imageBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 12px 0 25px;
      .originPicBox {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        .lable {
          position: absolute;
          top: 0;
          left: 0;
          width: 36px;
          height: 18px;
          font-size: 12px;
          border-radius: 8px 0px;
          color: #fff;
          background: #0b99ff;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
      }
      .equalSign {
        width: 18px;
        height: 18px;
        margin: 0 3px;
      }
      .resultImg {
        width: 90px;
        height: 90px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        .mask {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          .aiFail {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .statusBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .statusText {
        font-size: 12px;
        color: #333;
      }
      .errorText {
        color: #ec275f;
      }
    }
    .queueBtn {
      width: 60px;
      height: 26px;
      border-radius: 26px;
      font-size: 12px;
      color: #fff;
      background: #0b99ff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .successBtn {
      width: 60px;
      height: 26px;
      border-radius: 26px;
      font-size: 12px;
      color: #333;
      background: #fbd13d;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .failBtn {
      width: 60px;
      height: 26px;
      border-radius: 26px;
      font-size: 12px;
      color: #fff;
      background: #7f7f7f;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .leftBox {
      .cover {
        height: 68px;
        width: 68px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;
      }
    }
    .recordInfo {
      .id {
        font-size: 16px;

        span {
          font-size: 12px;
          color: rgb(168, 168, 168);
        }
      }

      .time {
        font-size: 10px;
        color: rgb(168, 168, 168);
        margin-top: 9px;

        span {
          font-size: 12px;
          color: white;
          margin-left: 5px;
        }
      }
    }
    .rightIcon {
      margin-left: 35px;
      .delBtn {
        height: 20px;
        width: 20px;
      }
    }
  }
}
</style>
