import { render, staticRenderFns } from "./aiChangeVideoFaceTab.vue?vue&type=template&id=e65e2e06&scoped=true&"
import script from "./aiChangeVideoFaceTab.vue?vue&type=script&lang=js&"
export * from "./aiChangeVideoFaceTab.vue?vue&type=script&lang=js&"
import style0 from "./aiChangeVideoFaceTab.vue?vue&type=style&index=0&id=e65e2e06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e65e2e06",
  null
  
)

export default component.exports