<template>
  <div class="aiRecordTab">
    <van-tabs
      v-model="activeValue"
      title-active-color="rgba(251, 209, 61, 1)"
      title-inactive-color="#333333"
      :animated="true"
    >
      <van-tab name="queue" title="排队 /">
        <aiChangeVideoFaceList ref="changeFaceChildRef1" status="0"></aiChangeVideoFaceList>
      </van-tab>
      <van-tab name="success" title="成功 /">
        <aiChangeVideoFaceList ref="changeFaceChildRef2" status="1"></aiChangeVideoFaceList>
      </van-tab>
      <van-tab name="fail" title="失败 /">
        <aiChangeVideoFaceList ref="changeFaceChildRef3" status="-1"></aiChangeVideoFaceList>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import aiChangeVideoFaceList from './aiChangeVideoFaceList.vue';

export default {
  name: "aiChangeVideoFaceTab",
  components: {
    aiChangeVideoFaceList
  },
  computed: {

  },
  data() {
    return {
      activeValue: 0,
      showBigImg: false,
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      let name = this.$route.query['name'];
      if (name) {
        this.activeValue = name;
      } else {
        this.activeValue = 'queue';
      }
    });
  },
  methods: {
    delMethod(id) {
      if (this.activeValue == 'queue') {
        this.$refs.changeFaceChildRef1.delId(id);
      } else if (this.activeValue == 'success') {
        this.$refs.changeFaceChildRef2.delId(id);
      } else if (this.activeValue == 'fail') {
        this.$refs.changeFaceChildRef3.delId(id);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.aiRecordTab {
 height: 100%;
}
/deep/ .van-tabs__wrap {
  padding-top: 15px;
  height: 30px !important;
  justify-content: flex-start !important;
}
/deep/ .van-tab {
  display: contents;
}

/deep/ .van-tab__text {
  font-size: 12px;
  margin-right: 5px !important;
}

/deep/ .van-tabs__nav {
  background: #F1F1F1;
}

/deep/ .van-tabs__nav--line {
  padding-left: 14px !important;
}

/deep/ .van-tabs__line {
  display: none !important;
}
</style>
