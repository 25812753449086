<template>
  <div class="vertical_video_warp">
   <HeadBar></HeadBar>
    <div id="video">
      <div class="avoid_sliding" @click="pause"></div>
    </div>
  </div>
</template>

<script>
import DPlayer from "dplayer";
import { mapGetters } from "vuex";
import { getLocalItem } from "@/utils/longStorage";
import HeadBar from "@/components/HeadBar/index.vue";

export default {
 components: {HeadBar},
  props: {
    sourceURL: String,
  },
  computed: {
    ...mapGetters({
      baseUrl: "baseUrl",
      userInfo: "userInfo",
    }),
  },
  data() {
    return {
      player: null,
    };
  },
  methods: {
    pause() {
      this.player.pause();
    },
    initVideo() {
      this.player = new DPlayer({
        container: document.getElementById("video"),
        video: {
          url: this.getPath(),
          type: "hls",
        },
        loop: true,
        autoplay: true,
        screenshot: true,
        hotkey: true,
      });
    },
    getPath() {
      const token = getLocalItem("token");
      return `${this.baseUrl}vid/h5/m3u8/${this.sourceURL}?token=${token}`;
    },
  },
  mounted() {
    this.initVideo();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.destroy();
    }
    this.player = null;
  },
};
</script>

<style lang="scss" scoped>
.vertical_video_warp {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background: #000000;
  align-items: center;
  /deep/ .headBar {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 9999999;
   width: 100%;
  }
  #video {
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    position: relative;
    .avoid_sliding {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      top: 0;
    }
  }
}
</style>
