<template>
 <div class="aiUndress">
  <div class="navBar">
   <div @click="$router.go(-1)">
    <svg-icon class="navArrow" iconClass="navArrow" />
   </div>
   <div class="navTitle">生成记录</div>
  </div>
  <van-tabs
    v-model="active"
    title-active-color="#333333"
    title-inactive-color="#999999"
    color="#999999"
    animated
    @change="onChange"
  >
   <van-tab>
    <template #title> AI脱衣 </template>
    <div class="content">
     <AiUndressTab ref="undressRef" />
    </div>
   </van-tab>
   <van-tab>
    <template #title> 视频换脸 </template>
    <div class="content">
     <AiChangeVideoFaceTab ref="changeVideoFaceRef" />
    </div>
   </van-tab>
   <van-tab>
    <template #title> 图片换脸 </template>
    <div class="content">
     <AiChangeImgFaceTab ref="changeImgFaceRef" />
    </div>
   </van-tab>
  </van-tabs>

  <van-overlay
    :show="showBigImg"
    @click="showBigImg = false"
    v-if="showBigImg"
    z-index="9999"
  >
    <div class="swiperWrapper">
      <!-- <ImgDecypt class="tag-img" :src="imgUrls" :key="imgUrls"></ImgDecypt> -->
      <van-swipe
        @change="onChange"
        class="my-swipe"
        :show-indicators="false"
        ref="swipeRef"
      >
        <van-swipe-item v-for="item in imgUrls" :key="item">
          <ImgDecypt class="tag-img" :src="item" :key="item"></ImgDecypt>
        </van-swipe-item>
      </van-swipe>
      <div class="custom-indicator">
        {{ Number(currentIndex) + 1 }}/{{ imgUrls ? imgUrls.length : 0 }}
      </div>
    </div>
  </van-overlay>
  <van-overlay :show="showPlayVideo" z-index="9999">
    <SvgIcon
      className="close"
      iconClass="close"
      @click="showPlayVideo = false"
    ></SvgIcon>
    <div class="wrapper blackBg">
      <SmallVideo
        v-if="showPlayVideo"
        class="videoBox"
        :sourceURL="sourceURL"
        :key="sourceURL"
      />
    </div>
  </van-overlay>
  <van-overlay
    :show="showDelDialog"
    @click="showDelDialog = false"
    z-index="9999"
  >
    <div class="wrapper">
      <div class="delBox">
        <div class="title">您是否移除该内容?</div>
        <div class="btnBox flex-center-between">
          <div class="cancel" @click="showDelDialog = false">取消</div>
          <div class="confirm" @click="confirm">确定</div>
        </div>
      </div>
    </div>
  </van-overlay>
  <van-overlay
    :show="showReasonDialog"
    @click="showReasonDialog = false"
    z-index="9999"
  >
    <div class="wrapper">
      <div class="reasonBox">
        <div class="title">拒绝原因</div>
        <div class="reason">{{ reason }}</div>
      </div>
    </div>
  </van-overlay>
 </div>
</template>
<script>
import { Toast } from "vant";
import { uploadImg } from "@/api/app";
import {
 generateImg,
 getAiMod,
 delelteUndress,
 delelteChangeVideoFace,
 delelteChangeImgFace,
} from "@/api/mine";
import { mapGetters } from "vuex";
import ImgDecypt from "@/components/ImgDecypt";
import AiUndressTab from "./aiUndressTab.vue";
import AiChangeVideoFaceTab from "./aiChangeVideoFaceTab.vue";
import AiChangeImgFaceTab from "./aiChangeImgFaceTab.vue";
import SvgIcon from "@/components/SvgIcon";
import SmallVideo from "@/components/SmallVideo";

export default {
 name: "aiRecord",
 components: {
  ImgDecypt,
  AiChangeImgFaceTab,
  AiChangeVideoFaceTab,
  AiUndressTab,
  SvgIcon,
  SmallVideo,
 },
 computed: {},
 data() {
  return {
   active: 0,
   showBigImg: false,
   imgUrls: [], // 需要显示的图片数组
   currentIndex: 0,
   showDelDialog: false,
   showReasonDialog: false,
   reason: "", // 拒绝原因
   type: "", // 脱衣删除还是换脸删除
   delId: "", // 删除ID
   showPlayVideo: false,
   sourceURL: "", // 换脸后的视频链接
  };
 },
 provide() {
  return {
   openBigImg: this.openBigImg,
   openPlayVideo: this.openPlayVideo,
   openDelUndressDialog: this.openDelUndressDialog,
   openDelChangeImgFaceDialog: this.openDelChangeImgFaceDialog,
   openDelChangeVideoFaceDialog: this.openDelChangeVideoFaceDialog,
   openReasonDialog: this.openReasonDialog,
  };
 },
 created() {
  // this.aiUndressPrice = this.$store.getters.appConfig.aiUndressPrice || 0;
  // this.freeCount = this.userInfo.aiUndressCount || 0;
 },
 mounted() {},
 methods: {
  async confirm() {
   let req = {
    id: this.delId,
   };
   try {
    if (this.type == "undress") {
     await this.$Api(delelteUndress, req);
     this.$refs.undressRef.delMethod(this.delId);
    } else if (this.type == "changeVideoFace") {
     await this.$Api(delelteChangeVideoFace, req);
     this.$refs.changeVideoFaceRef.delMethod(this.delId);
    } else if (this.type == "changeImgFace") {
     await this.$Api(delelteChangeImgFace, req);
     this.$refs.changeImgFaceRef.delMethod(this.delId);
    }
    this.delId = "";
    this.showDelDialog = false;
    Toast("删除成功");
   } catch (e) {
    console.log(e);
   }
  },
  openBigImg(urls) {
  //  console.log(urls);
   this.currentIndex = 0;
   this.imgUrls = urls;
   this.showBigImg = true;
  },
  openPlayVideo(sourceURL) {
   console.log(sourceURL);
   this.sourceURL = sourceURL;
   this.showPlayVideo = true;
  },
  onChange(index) {
   this.currentIndex = index;
  },
  openDelUndressDialog(delId) {
   this.delId = delId;
   this.type = "undress";
   this.showDelDialog = true;
  },
  openDelChangeVideoFaceDialog(delId) {
   this.delId = delId;
   this.type = "changeVideoFace";
   this.showDelDialog = true;
  },
  openDelChangeImgFaceDialog(delId) {
   this.delId = delId;
   this.type = "changeImgFace";
   this.showDelDialog = true;
  },
  openReasonDialog(reason) {
   this.reason = reason;
   this.showReasonDialog = true;
  },
 },
};
</script>
<style lang="scss" scoped>
/deep/ .van-tabs__wrap {
 width: 100%;
 height: 44px;
 margin-bottom: 4px;
 display: flex;
 justify-content: center;
}

/deep/ .van-tabs__line {
 width: 18px !important;
 height: 2px !important;
 border-radius: 1px !important;
 background: rgba(251, 209, 61, 1) !important;
 bottom: 18px !important;
}

/deep/ .van-tab {
 padding: 0;
 width: 65px;
 margin: 0 10px;
}
.content {
 background: #f1f1f1;
 overflow: auto;
 height: calc(100vh - 44px);
}
.aiUndress {
 height: 100%;
 .navBar {
  height: 44px;
  padding: 0 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #e6e6e6;
  .navArrow {
   width: 17px;
   height: 17px;
   margin-right: 26px;
  }
  .navTitle {
   flex: 2;
   text-align: center;
   font-size: 16px;
   font-weight: 500;
   color: #333333;
  }
  .rightBtn {
   color: #666666;
  }
 }
 .main {
  padding: 11px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 44px);
  -webkit-overflow-scrolling: touch;
  .uploadBox {
   height: 178px;
   width: 352px;
   border-radius: 6px;
   background: url("~@/assets/png/aiUploadBg.png") no-repeat;
   background-size: 100% 100%;
   margin: 0 auto;
  }
  .cover {
   height: 178px;
   width: 352px;
   border-radius: 6px;
   overflow: hidden;
   margin: 0 auto;
  }
  .precautions {
   font-size: 16px;
   color: rgb(102, 102, 102);
   margin-top: 18px;
  }
  p {
   font-size: 14px;
   color: rgb(162, 159, 159);
   line-height: 20px;
  }
  .generateBox {
   height: 166px;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 18px auto 0;
   background: url("./../../../assets/png/caseDiagram.png") no-repeat;
   background-size: 100% 100%;
  }
  .bottom {
   margin: 60px 0;
   padding: 0 20px;
   .priceBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 25px;
    color: rgb(54, 54, 54);
   }
   .submit {
    height: 40px;
    background-color: #7145e7;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: white;
    border-radius: 40px;
    margin-top: 16px;
   }
  }
 }
 /deep/ .cover img {
  object-fit: unset !important;
 }
 /deep/ .van-image__img {
  width: unset;
 }
 /deep/ .van-uploader {
  width: 100%;
  .van-uploader__wrapper {
   width: 100%;
   .van-uploader__input-wrapper {
    width: 100%;
   }
  }
 }
}
.swiperWrapper {
 background: black;
 height: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 .my-swipe {
  height: 80vh;
  background: black;
  color: #fff;
  text-align: center;
  .tag-img {
   width: 100vw;
   height: 100%;
  }
 }
 .custom-indicator {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 16px;
  color: white;
 }
}
.close {
 height: 30px;
 width: 30px;
 position: fixed;
 top: 20px;
 right: 20px;
 z-index: 10000;
}
.wrapper {
 display: flex;
 align-items: center;
 justify-content: center;
 height: 100%;
 .delBox {
  //height: 123px;
  //width: 266px;
  border-radius: 8px;
  background: #FFFFFF;
  color: #333333;
  //background: url('~@/assets/png/removeBg.png') no-repeat;
  //background-size: 100% 100%;
  padding: 25px 55px;
  .title {
   font-size: 18px;
  }
  .btnBox {
   font-size: 15px;
   margin-top: 45px;
   padding: 0 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   .cancel {
    margin-right: 40px;
    //color: rgb(205, 205, 205);
   }
  }
 }
 .videoBox {
  height: 100%;
  width: 100%;
  background-color: pink;
 }
 .reasonBox {
  .title {
   font-size: 16px;
   text-align: center;
  }
  .reason {
   font-size: 14px;
   text-align: center;
   margin-top: 20px;
   padding: 0 16px;
  }
 }
}
.blackBg {
 background: black;
}
/deep/ .vanImage {
 display: flex;
 align-items: center;
}
/deep/ img {
 height: auto !important;
}
/deep/ .van-popup--center {
 max-width: unset;
}
</style>
